import React, { useState, useEffect } from 'react';
import './CookiePopup.css'; // Import the styles

const CookiePopup = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (!cookieConsent) {
      setVisible(true); // Show the popup if no consent has been given
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setVisible(false); // Hide the popup
    // You can trigger cookie tracking code here
  };

  const handleDecline = () => {
    localStorage.setItem('cookieConsent', 'declined');
    setVisible(false); // Hide the popup
  };

  if (!visible) {
    return null; // Don't render anything if the popup isn't visible
  }

  return (
    <div className="cookie-popup">
      <p>This website uses only esssential cookies to ensure you get the best experience on our website.</p>
      <button onClick={handleAccept}>Accept</button>
      <button onClick={handleDecline}>Decline</button>
    </div>
  );
};

export default CookiePopup;
