import React, { useState } from 'react';
import axios from 'axios';

const CreateListing = ({ token, fetchListings }) => {
  const [propertyType, setPropertyType] = useState('House'); // Default property type
  const [listingType, setListingType] = useState('For Sale');
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [landSize, setLandSize] = useState('');
  const [bedrooms, setBedrooms] = useState('');
  const [bathrooms, setBathrooms] = useState('');
  const [floors, setFloors] = useState('');
  const [livingRoomSize, setLivingRoomSize] = useState('');
  const [houseType, setHouseType] = useState('Bungalow');
  const [documents, setDocuments] = useState([]);
  const [estate, setEstate] = useState('');
  const [propertySize, setPropertySize] = useState('');
  const [ceilingHeight, setCeilingHeight] = useState('');
  const [floorLoadCapacity, setFloorLoadCapacity] = useState('');
  const [commercialType, setCommercialType] = useState('Office');
  const [location, setLocation] = useState({ state: '', lga: '', town: '', address: '' });
  const [images, setImages] = useState([]);
  const [description, setDescription] = useState('');

  const handleCreateListing = async (e) => {
    e.preventDefault();

    const listingData = new FormData();
    listingData.append('propertyType', propertyType);
    listingData.append('listingType', listingType);
    listingData.append('title', title);
    listingData.append('estate', estate); // Append in handleCreateListing
    listingData.append('price', price);
    
    if (propertyType === 'House' || propertyType === 'Apartment') {
      listingData.append('bedrooms', bedrooms);
      listingData.append('bathrooms', bathrooms);
      listingData.append('floors', floors);
      listingData.append('livingRoomSize', livingRoomSize);
      listingData.append('houseType', houseType);
    }
    
    if (propertyType === 'Land' || propertyType === 'Commercial') {
      listingData.append('landSize', landSize);
    }
    
    if (propertyType === 'Commercial') {
      listingData.append('propertySize', propertySize);
      listingData.append('ceilingHeight', ceilingHeight);
      listingData.append('floorLoadCapacity', floorLoadCapacity);
      listingData.append('commercialType', commercialType);
    }

    if (listingType === 'For Sale') {
      documents.forEach(doc => listingData.append('documents', doc));
    }

    listingData.append('state', location.state);
    listingData.append('lga', location.lga);
    listingData.append('town', location.town);
    listingData.append('address', location.address);
    images.forEach(image => listingData.append('images', image));
    listingData.append('description', description);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}listings`, listingData, {
        withCredentials: true, // This ensures cookies are included
      });

      console.log(response.data);
      // Clear form and refresh listings after success
      setTitle('');
      setListingType('For Sale');
      setPrice('');
      setLocation({ state: '', town: '', lga: '', address: '' });
      setDescription('');
      setImages([]); // Clear selected images
      setDocuments([]); // Clear selected documents
      setBedrooms ('');
      setEstate('');
      setBathrooms('');
      setFloors('');
      fetchListings();

    } catch (error) {
      console.error(error.response ? error.response.data : error.message); // Log error for debugging
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const maxSize = 20 * 1024 * 1024; // 20MB

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > maxSize) {
        alert(`File ${files[i].name} is too large. Max file size is 20MB.`);
        return;
      }
    }

    setImages(files); // Store selected files in the state
  };

  const handleDocumentChange = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      setDocuments([...documents, value]); // Add the selected document
    } else {
      setDocuments(documents.filter((doc) => doc !== value)); // Remove the unselected document
    }
  };

  return (
    <form onSubmit={handleCreateListing}>
      <label className="label-class">Property Type:</label>
      <select value={propertyType} onChange={(e) => setPropertyType(e.target.value)} required>
        <option value="House">House</option>
        <option value="Land">Land</option>
        <option value="Apartment">Apartment</option>
        <option value="Commercial">Commercial Property</option>
      </select>

      <label className="label-class">Listing Type:</label>
      <select value={listingType} onChange={(e) => setListingType(e.target.value)} required>
        <option value="For Sale">For Sale</option>
        <option value="Lease">Lease</option>
        <option value="Rent">Rent</option>
        {propertyType !== 'Land' && <option value="Short Let">Short Let</option>}
      </select>

      <label className="label-class">Title:</label>
      <input
        type="text"
        placeholder="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        required
      />

      <label className="label-class">Asking Price:</label>
      <input
        type="number"
        placeholder="Price"
        value={price}
        onChange={(e) => setPrice(e.target.value)}
        required
      />
      
      {propertyType !== 'Land' && (
        <>
          <label className="label-class">Number of Bedrooms:</label>
          <input
            type="number"
            placeholder="Bedrooms"
            value={bedrooms}
            onChange={(e) => setBedrooms(e.target.value)}
            required
          />

          <label className="label-class">Number of Bathrooms:</label>
          <input
            type="number"
            placeholder="Bathrooms"
            value={bathrooms}
            onChange={(e) => setBathrooms(e.target.value)}
            required
          />

          <label className="label-class">Number of Floors:</label>
          <input
            type="number"
            placeholder="Floors"
            value={floors}
            onChange={(e) => setFloors(e.target.value)}
            required
          />
          <label className="label-class">Living Room Size (sqm):</label>
          <input
            type="number"
            placeholder="Living Room Size (sqm)"
            value={livingRoomSize}
            onChange={(e) => setLivingRoomSize(e.target.value)}
            required
          />
          <label className="label-class">Type of House:</label>
          <select value={houseType} onChange={(e) => setHouseType(e.target.value)} required>
            <option value="Bungalow">Bungalow</option>
            <option value="Duplex">Duplex</option>
            <option value="Mansion">Mansion</option>
            <option value="Block of Flats">Block of Flats</option>
          </select>
        </>
      )}
      
      {propertyType === 'Land' && (
        <>
        <label className="label-class">Land Total Size (sqm):</label>
        <input
          type="number"
          placeholder="Land Size (sqm)"
          value={landSize}
          onChange={(e) => setLandSize(e.target.value)}
          required
        />
        </>
      )}

      {listingType === 'For Sale' && (
        <>
        <label className="label-class">Select Types Of Documents This Property Has:</label>
        <div className="checkbox-group-documents">
        
          <label>
            <input
              type="checkbox"
              value="C of O"
              checked={documents.includes('C of O')}
              onChange={handleDocumentChange}
            />
            Certificate of Occupancy (C of O)
          </label>
          <label>
            <input
              type="checkbox"
              value="Power of Attorney"
              checked={documents.includes('Power of Attorney')}
              onChange={handleDocumentChange}
            />
            Power of Attorney
          </label>
          <label>
            <input
              type="checkbox"
              value="Deed of Conveyance"
              checked={documents.includes('Deed of Conveyance')}
              onChange={handleDocumentChange}
            />
            Deed of Conveyance
          </label>
          <label>
            <input
              type="checkbox"
              value="Deed of Assignment"
              checked={documents.includes('Deed of Assignment')}
              onChange={handleDocumentChange}
            />
            Deed of Assignment
          </label>
          <label>
            <input
              type="checkbox"
              value="Governor’s Consent"
              checked={documents.includes('Governor’s Consent')}
              onChange={handleDocumentChange}
            />
            Governor’s Consent
          </label>
          <label>
            <input
              type="checkbox"
              value="Survey Plan"
              checked={documents.includes('Survey Plan')}
              onChange={handleDocumentChange}
            />
            Survey Plan
          </label>
          <label>
            <input
              type="checkbox"
              value="Excision"
              checked={documents.includes('Excision')}
              onChange={handleDocumentChange}
            />
            Excision
          </label>
          <label>
            <input
              type="checkbox"
              value="Gazette"
              checked={documents.includes('Gazette')}
              onChange={handleDocumentChange}
            />
            Gazette
          </label>
          <label>
            <input
              type="checkbox"
              value="Letter of Allocation"
              checked={documents.includes('Letter of Allocation')}
              onChange={handleDocumentChange}
            />
            Letter of Allocation
          </label>
        </div>
        </>
      )}

      <div>
        <label className="label-class">Estate:</label>
        <input
          type="text"
          value={estate}
          onChange={(e) => setEstate(e.target.value)}
        />
      </div>

      {propertyType === 'Commercial' && (
        <>
        <div>
          <label className="label-class">Property Size:</label>
          <input
            type="number"
            value={propertySize}
            onChange={(e) => setPropertySize(e.target.value)}
          />
        </div>

        <div>
          <label className="label-class">Ceiling Height:</label>
          <input
            type="number"
            value={ceilingHeight}
            onChange={(e) => setCeilingHeight(e.target.value)}
          />  
        </div>

        <div>
          <label className="label-class">Floor Load Capacity:</label>
          <input
            type="number"
            value={floorLoadCapacity}
            onChange={(e) => setFloorLoadCapacity(e.target.value)}
          />
        </div>

        <div>
          <label className="label-class">Commercial Property Type:</label>
          <select
            value={commercialType}
            onChange={(e) => setCommercialType(e.target.value)}
          >
            <option value="">Select Type</option>
            <option value="Office">Office</option>
            <option value="Retail">Retail</option>
            <option value="Industrial">Industrial</option>
            <option value="Warehouse">Warehouse</option>
            <option value="Mixed-use">Mixed-use</option>
          </select>
        </div>
        </>
      )}

      <label className="label-class">State:</label>
      <input
        type="text"
        placeholder="State"
        value={location.state}
        onChange={(e) => setLocation({ ...location, state: e.target.value })}
        required
      />

      <label className="label-class">LGA:</label>
      <input
        type="text"
        placeholder="LGA"
        value={location.lga}
        onChange={(e) => setLocation({ ...location, lga: e.target.value })}
        required
      />
      <label className="label-class">City/Town:</label>
      <input
        type="text"
        placeholder="Town"
        value={location.town}
        onChange={(e) => setLocation({ ...location, town: e.target.value })}
        required
      />
      <label className="label-class">Property Address:</label>
      <input
        type="text"
        placeholder="Address"
        value={location.address}
        onChange={(e) => setLocation({ ...location, address: e.target.value })}
        required
      />
      <label className="label-class">Property Images:</label>
      <input type="file" name="images" multiple onChange={handleImageChange} required />

      <label className="label-class">Property Description:</label>
      <textarea
        placeholder="Describe other features to attract client eg: tarred road, steady electricity, water supply etc"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        required
      ></textarea>

      <button type="submit">Create Listing</button>
    </form>
  );
};

export default CreateListing;
