import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './AdminLogin.css';

const AdminLogin = ({ onLogin, closeModal }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false); // State to toggle forgot password view

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Clear any existing error messages

    if (forgotPassword) {
      handlePasswordResetRequest();
    } else {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}admin/login`, {
          email,
          password,
        }, {
          withCredentials: true,
        });

        if (res.status === 200) {
          onLogin(res.data.token);
          setSuccessMessage('Login successful!');
          closeModal();
          window.location.reload();
        } else {
          setError('Unexpected response from server. Please try again.');
        }
      } catch (err) {
        handleError(err);
      }
    }
  };

  // Handle password reset request
  const handlePasswordResetRequest = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/admin/forgot-password`, {
        email,
      });
      if (res.status === 200) {
        setSuccessMessage('Password reset link sent to your email.');
      } else {
        setError('Unexpected response from server. Please try again.');
      }
    } catch (err) {
      handleError(err);
    }
  };

  const handleError = (err) => {
    if (err.response) {
      if (err.response.status === 400) {
        setError('Invalid credentials. Please try again.');
      } else {
        setError('An unexpected error occurred. Please try again later.');
      }
    } else if (err.request) {
      setError('Network error. Please check your connection and try again.');
    } else {
      setError('An error occurred: ' + err.message);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="admin-login-container">
      <h2>{forgotPassword ? 'Forgot Password' : 'Admin Login'}</h2>
      <form onSubmit={handleSubmit} className="admin-login-form">
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Enter your email"
          />
        </div>

        {!forgotPassword && (
          <div className="form-group">
            <label>Password</label>
            <div className="password-wrapper">
              <input
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Enter your password"
              />
              <span onClick={togglePasswordVisibility} className="password-icon">
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </span>
            </div>
          </div>
        )}

        {error && <div className="error-message">{error}</div>}
        {successMessage && <div className="success-message">{successMessage}</div>}

        <button type="submit" className="login-btn">
          {forgotPassword ? 'Request Reset Link' : 'Login'}
        </button>
      </form>

      <div className="forgot-password-link">
        {!forgotPassword ? (
          <p onClick={() => setForgotPassword(true)}>
            Forgot Password?
          </p>
        ) : (
          <p onClick={() => setForgotPassword(false)}>
            Back to Login
          </p>
        )}
      </div>
    </div>
  );
};

export default AdminLogin;
