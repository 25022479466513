import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AdminRegister from './AdminRegister';
import AdminLogin from './AdminLogin';
import './AdminPanel.css';
import './CreateListing.css';
import CreateListing from './CreateListing';
import EditListing from './EditListing'; // Import EditListing component

const AdminPanel = () => {
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [listings, setListings] = useState([]);
  const [editingListing, setEditingListing] = useState(null);
  // const [newImages, setNewImages] = useState([]); // State to hold newly uploaded images
  const [adminDetails, setAdminDetails] = useState(null); // Store admin details like username and email
  const [loginTrigger, setLoginTrigger] = useState(false);
  
  // Modal states for login and registration
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);  // Delete confirmation modal
  const [listingToDelete, setListingToDelete] = useState(null);  // Track the listing to delete
  
  // Check if admin is logged in by checking the token
  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}admin/status`, { withCredentials: true });
        if (res.status === 200) {
          setToken(res.data.token);

          // Fetch admin details
          const fetchAdminDetails = async () => {
            try {
              const adminRes = await axios.get(`${process.env.REACT_APP_API_URL}admin/details`, { withCredentials: true });
              setAdminDetails(adminRes.data); // Store admin details
            } catch (error) {
              console.error('Error fetching admin details:', error);
            }
          };
          await fetchAdminDetails();
        }
      } catch (error) {
        console.error('Error checking login status:', error);
        setToken(null);
      } finally {
        setLoading(false);
      }
    };
    checkLoginStatus();
  }, [loginTrigger]);

  // Handle modal switching after successful registration
  const handleRegistrationSuccess = () => {
    setShowRegisterModal(false); // Close the register modal
    setShowLoginModal(true); // Open the login modal
  };

  // Fetch Listings function
  const fetchListings = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}listings`);
      setListings(res.data);
    } catch (err) {
      console.error('Error fetching listings:', err);
    }
  };

  // Fetch listings when the component mounts
  useEffect(() => {
    fetchListings();
  }, []);

   // Trigger delete confirmation modal
  const confirmDelete = (id) => {
    setListingToDelete(id); // Set the listing to be deleted
    setShowDeleteModal(true); // Show the confirmation modal
  };

  // Handle listing deletion
  const deleteListing = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}listings/${listingToDelete}`, { withCredentials: true });
      setListings(listings.filter(listing => listing._id !== listingToDelete));
      alert('Listing deleted successfully');
      setShowDeleteModal(false); // Close the modal
    } catch (error) {
      alert('Failed to delete the listing');
    }
  };

  // Close the delete confirmation modal
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  // Handle listing editing
  const editListing = async (id, updatedListing, newImages) => {
    try {
      const formData = new FormData();
      formData.append('updatedListing', JSON.stringify(updatedListing));
  
      if (newImages.length > 0) {
        newImages.forEach((image, index) => {
          formData.append(`newImages[${index}]`, image);
        });
      }
  
      const res = await axios.put(`${process.env.REACT_APP_API_URL}listings/${id}`, formData, {
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setListings(listings.map(listing => (listing._id === id ? res.data : listing)));
      setEditingListing(null); // Close the edit form
      alert('Listing updated successfully');
    } catch (error) {
      alert('Failed to update the listing');
    }
  };
  

  // Handle logout
  const handleLogout = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}admin/logout`, {}, { withCredentials: true });
      
      // Clear token and admin details in the state
      setToken(null);
      setAdminDetails(null);
      
      // Reload or navigate to ensure logged-out state
      window.location.reload(); // or use history.push('/login');
    } catch (error) {
      console.error('Failed to logout:', error);
    }
  };
  
  // Handle the login trigger
  const handleLoginTrigger = () => {
    setLoginTrigger(!loginTrigger); // Toggle loginTrigger to re-run the effect
  };

  // If loading, show a loading spinner or placeholder content
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="admin-panel">
      
      {!token ? (
        <div className="auth-section">
          <button className="auth-button" onClick={() => setShowRegisterModal(true)}>Sign Up</button>
          <button className="auth-button" onClick={() => setShowLoginModal(true)}>Login</button>

          {showRegisterModal && (
            <div className="modal">
              <div className="modal-content">
                <span className="close" onClick={() => setShowRegisterModal(false)}>&times;</span>
                <AdminRegister onSuccess={handleRegistrationSuccess} />
              </div>
            </div>
          )}

          {showLoginModal && (
            <div className="modal">
              <div className="modal-content">
                <span className="close" onClick={() => setShowLoginModal(false)}>&times;</span>
                <AdminLogin onLogin={(token) => {
                  setToken(token);
                  handleLoginTrigger();
                  setShowLoginModal(false);
                }} />
              </div>
            </div>
          )}

        </div>
      ) : (
        <div className="admindashboard">
          {adminDetails && (
            <div className="admin-details">
              <h3>Welcome {adminDetails.username}!</h3>
              <p>This is your Admin Panel, your global office.</p>
              <p>Remember: login details to this place should be confidential</p>
            </div>
          )}
        
          <div className="logout-container">
            <button className="logout-button" onClick={handleLogout}>Logout</button>
          </div>

          <h3 className="form-title">Add New Listing</h3>
          <CreateListing token={token} fetchListings={fetchListings} />

          <h2 className="listings-title">Current Listings</h2>
          {listings.length === 0 ? (
            <p className="no-listings">No listings found.</p>
          ) : (
            <ul className="listings-container">
              {listings.map((listing) => (
                <li className="listing-item" key={listing._id}>
                  {editingListing && editingListing._id === listing._id ? (
                    <EditListing
                      listing={editingListing}
                      onSaveChanges={(updatedListing, newImages) => editListing(listing._id, updatedListing, newImages)}
                      onCancel={() => setEditingListing(null)}
                    />
                  ) : (
                    <div>
                      <div className="image-container1">
                        <img src={`${process.env.REACT_APP_API_URL}${listing.images[0]}`} alt={listing.title} loading="lazy" />
                        <p className="listing-type">{listing.listingType}</p>
                        {/* Conditionally show "New" label */}
                        {new Date() - new Date(listing.createdAt) <= 14 * 24 * 60 * 60 * 1000 && (
                          <p className="new-label">New</p>
                        )}
                      </div>
                      <h4>{listing.title}</h4>
                      <p className="listing-price">N{listing.price.toLocaleString()}NGN</p>
                      <p className="listing-details">
                        {listing.bedrooms || 0} Bed | {listing.bathrooms || 0} Bath | Parlor: {listing.livingRoomSize || 0}²m
                      </p>
                      <p className="listing-location">
                        Address: {listing.location.state}, {listing.location.town}, {listing.location.lga}, {listing.location.address}
                      </p>
                      <p className="listing-date">
                        Date Listed: {new Date(listing.createdAt).toLocaleDateString()}
                      </p>
                      <div className="listing-buttons">
                        <button className="edit-butn" onClick={() => setEditingListing(listing)}>Edit</button>
                        <button className= "delete-btn" onClick={() => confirmDelete(listing._id)}>Delete</button>
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          )}

          {/* Delete confirmation modal */}
          {showDeleteModal && (
            <div className="modal">
              <div className="modal-content">
                <span className="close" onClick={closeDeleteModal}>&times;</span>
                <h3>Confirm Delete</h3>
                <p>Are you sure you want to delete this listing?</p>
                <button onClick={deleteListing}>Yes, Delete</button>
                <button onClick={closeDeleteModal}>Cancel</button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminPanel;
