import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';
import './ListingDetail.css';

const ListingDetail = () => {
  const { id } = useParams(); // Get the listing ID from the URL
  const [listing, setListing] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    wantsCall: false,
  });
  const [formStatus, setFormStatus] = useState('');
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  const slider1 = useRef(null);
  const slider2 = useRef(null);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  useEffect(() => {
    const fetchListing = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}listings/${id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch listing');
        }
        const data = await response.json();
        setListing(data);
      } catch (error) {
        console.error('Error fetching listing:', error);
        setFormStatus('Error fetching listing. Please try again later.'); // User-friendly error message
      } finally {
        setLoading(false);
      }
    };

    fetchListing();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const messageBody = createMessageBody();
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}contact/send-message`, {
        method: 'POST',  // Specify that this is a POST request
        headers: {
          'Content-Type': 'application/json',  // Set content type for JSON data
        },
        body: JSON.stringify({ 
          ...formData, 
          propertyId: id, 
          message: messageBody,
        }),  // Send form data in the body of the request
      });
  
      const result = await response.json();
      if (response.ok) {
        setFormStatus(result.message);
        resetFormData();
      } else {
        throw new Error(result.message || 'Something went wrong');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setFormStatus('Error sending message. Please try again later.'); // User-friendly error message
    }
  };
  

  const createMessageBody = () => `
    Name: ${formData.name}
    Email: ${formData.email}
    Phone: ${formData.phone || 'N/A'}
    Message: ${formData.message}
    Wants Call: ${formData.wantsCall ? 'Yes' : 'No'}

    Property Details:
    Title: ${listing.title}
    Type: ${listing.propertyType}
    Price: N${listing.price.toLocaleString()} NGN
    Image: ${process.env.REACT_APP_API_URL}${listing.images[0]}
  `;

  const resetFormData = () => {
    setFormData({
      name: '',
      email: '',
      phone: '',
      message: '',
      wantsCall: false,
    });
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!listing) {
    return <p>Listing not found.</p>;
  }

  // Slider settings
  const settingsMain = {
    asNavFor: nav2,
    ref: slider1,
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const settingsThumb = {
    asNavFor: nav1,
    ref: slider2,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,
    infinite: true,
    centerMode: true,
    arrows: false,
    beforeChange: (current, next) => {
      slider1.current.slickGoTo(next);
    },
  };

  return (
    <div className="listing-detail">
      <h2>{listing.title} | Price: N{listing.price.toLocaleString()} NGN</h2>

      <div className="image-gallery">
        <Slider {...settingsMain} ref={slider1} className="slider-main">
          {listing.images.map((image, index) => (
            <div key={index} className="main-image">
              <img src={`${process.env.REACT_APP_API_URL}${image}`} alt={`Listing ${index}`} loading="lazy" />
            </div>
          ))}
        </Slider>

        <Slider {...settingsThumb} ref={slider2} className="slider-nav">
          {listing.images.map((image, index) => (
            <div key={index} className="thumbnail">
              <img src={`${process.env.REACT_APP_API_URL}${image}`} alt={`Thumbnail ${index}`} loading="lazy" />
            </div>
          ))}
        </Slider>
      </div>

      <div className="property-details">
        <div className="date-created">
          <p>Date Listed: {new Date(listing.createdAt).toLocaleDateString()}</p>
        </div>

        <div className="details-section">
          <div className="details-group">
            <h3>Basic Details</h3>
            <p><strong>Price:</strong> N{listing.price.toLocaleString()} NGN</p>
            <p><strong>Property Type:</strong> {listing.propertyType}</p>
            <p><strong>Listing Type:</strong> {listing.listingType}</p>
          </div>

          <div className="details-group">
            <h3>Location</h3>
            <p><strong>State:</strong> {listing.location.state}</p>
            <p><strong>LGA:</strong> {listing.location.lga}</p>
            <p><strong>Town:</strong> {listing.location.town}</p>
            {listing.location.address && <p><strong>Address:</strong> {listing.location.address}</p>}
            {listing.location.mapLink && (
              <p>
                <strong>Map:</strong> 
                <a href={listing.location.mapLink} target="_blank" rel="noopener noreferrer">View on Map</a>
              </p>
            )}
          </div>

          {listing.bedrooms || listing.bathrooms || listing.floors || listing.livingRoomSize || listing.houseType || listing.estate ? (
            <div className="details-group">
              <h3>House Details</h3>
              {listing.bedrooms && <p><strong>Bedrooms:</strong> {listing.bedrooms}</p>}
              {listing.bathrooms && <p><strong>Bathrooms:</strong> {listing.bathrooms}</p>}
              {listing.floors && <p><strong>Floors:</strong> {listing.floors}</p>}
              {listing.livingRoomSize && <p><strong>Living Room Size:</strong> {listing.livingRoomSize} m²</p>}
              {listing.houseType && <p><strong>House Type:</strong> {listing.houseType}</p>}
              {listing.estate && <p><strong>Estate:</strong> {listing.estate}</p>}
            </div>
          ) : null}

          {listing.documents && listing.documents.length > 0 && (
            <div className="details-group">
              <h3>Documents</h3>
              <ul>
                {listing.documents.map((document, index) => (
                  <li key={index}>{document}</li>
                ))}
              </ul>
            </div>
          )}

          <div className="details-group">
            <h3>Property Description</h3>
            {listing.description && <p>{listing.description}</p>}
          </div>
        </div>
      </div>

      <h3 className="contact-heading">Interested in this property? Contact us!</h3>
      <section className="contact-form">
        <div className="form-and-details">
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label htmlFor="phone">Phone (optional):</label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
             
                ></textarea>
                </div>
                <div className='checkbox'>
                  <label>
                    <input
                      type="checkbox"
                      name="wantsCall"
                      checked={formData.wantsCall}
                      onChange={handleChange}
                    />
                    Would you like to receive a call from us?
                  </label>
                </div>
                <button type="submit">Send Message</button>
              </form>
    
              {/* Status message */}
              {formStatus && <p className="form-status">{formStatus}</p>}
    
              {/* Company Contact Details */}
              <div className="company-contact-details">
                <h3>Contact Enddy Nation Real Estate Company LTD</h3>
                <p><strong>Address:</strong> The Lion Yard, World Bank, Last Roundabout, No. 2 Umuguma Road, Owerri, Imo State</p>
                <p><strong>Phone:</strong> 08069094410</p>
                <p><strong>Email:</strong> info@enddynationrealestate.com</p>
                <p>
                  <strong>Find Us on Map:</strong> 
                  <a 
                    href="https://www.google.com/maps?q=The+Lion+Yard,+World+Bank,+Last+Roundabout,+No.+2+Umuguma+Road,+Owerri,+Imo+State" 
                    target="_blank" 
                    rel="noopener noreferrer">
                    Get GPS Directions to Our Office
                  </a>
                </p>
              </div>
            </div>
          </section>
        </div>
      );
    };
    
    export default ListingDetail;
    