import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './Footer.css'; // Optional: For custom styling

const Footer = () => {
  return (
    <footer className="footer">
      <div>
        <p>&copy; 2024 Enddy Nation Real Estate Company LTD. All rights reserved.</p>
        
      </div>
      <div className="social-media">
        <a href="https://facebook.com/profile.php?id=61566442072739" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-facebook-f"></i>
        </a>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-x"></i>
        </a>
        <a href="https://www.instagram.com/enddy_nation_real_estate/" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-instagram"></i>
        </a>
      </div>

    </footer>
  );
};

export default Footer;
