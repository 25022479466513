import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdminRegister.css';

const AdminRegister = ({ onSuccess }) => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [emailError, setEmailError] = useState(''); // Error message for email
  const [usernameError, setUsernameError] = useState(''); // Error message for username
  const [isEmailValid, setIsEmailValid] = useState(true); // Flag for valid email
  const [isUsernameValid, setIsUsernameValid] = useState(true); // Flag for valid username

  // Function to check the availability of the username
  const checkUsername = async (username) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}admin/check`, { username });
      if (res.status === 200) {
        setUsernameError(''); // No error if available
        setIsUsernameValid(true); // Set valid username
      }
    } catch (err) {
      if (err.response && err.response.data.msg) {
        setUsernameError(err.response.data.msg);
        setIsUsernameValid(false); // Set invalid username
      }
    }
  };

  // Function to check the availability of the email
  const checkEmail = async (email) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}admin/check`, { email });
      if (res.status === 200) {
        setEmailError(''); // No error if available
        setIsEmailValid(true); // Set valid email
      }
    } catch (err) {
      if (err.response && err.response.data.msg) {
        setEmailError(err.response.data.msg);
        setIsEmailValid(false); // Set invalid email
      }
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}admin/register`, {
        username,
        email,
        password,
      });
      alert('Registration successful');
      onSuccess();  // Call the onSuccess callback here
      console.log(res.data); // Success message
    } catch (err) {
      alert('Registration failed');
      console.error('Error registering admin:', err);
    }
  };

  // Handle username changes and call checkUsername
  useEffect(() => {
    if (username) {
      checkUsername(username);
    }
  }, [username]);

  // Handle email changes and call checkEmail
  useEffect(() => {
    if (email) {
      checkEmail(email);
    }
  }, [email]);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="admin-register-container">
      <h2>Admin Registration</h2>
      <form onSubmit={handleSubmit} className="admin-register-form">
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailError && <span className="error-message">{emailError}</span>}
        </div>

        <div className="form-group">
          <label>Username:</label>
          <input
            type="text"
            placeholder="Enter username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          {usernameError && <span className="error-message">{usernameError}</span>}
        </div>

        <div className="form-group">
          <label>Password:</label>
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Confirm Password:</label>
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder="Confirm password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>

        <div className="form-group">
          <input
            type="checkbox"
            checked={showPassword}
            onChange={togglePasswordVisibility}
          />
          <label>Show Password</label>
        </div>

        {/* Disable the button if email or username is invalid */}
        <button type="submit" disabled={!isEmailValid || !isUsernameValid}>
          Register
        </button>
      </form>
    </div>
  );
};

export default AdminRegister;
