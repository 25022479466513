import React from 'react';
import './AboutUs.css'; // Assuming you have some styles for this component

const AboutUs = () => {
  return (
    <div className="about-us">
      <h1>About Us</h1>

      <section className="about-us-content">
        <h2>Welcome to Enddy Nation Real Estate Company LTD</h2>
        <p>
          Your trusted partner in real estate solutions. Located in the heart of Owerri, Imo State, we are committed to
          providing high-quality real estate services tailored to meet the unique needs of our clients.
        </p>

        <h3>Who We Are</h3>
        <p>
          Enddy Nation Real Estate Company LTD was established with a singular goal: to make property buying, selling, 
          and leasing as seamless as possible. We understand that real estate transactions are more than just business 
          deals—they represent life-changing decisions. That’s why we offer personalized services and expert guidance 
          to ensure you make informed choices every step of the way.
        </p>

        <h3>Our Services</h3>
        <p>We specialize in:</p>
        <ul>
          <li>Residential and Commercial Property Sales</li>
          <li>Property Leasing and Rentals</li>
          <li>Real Estate Investment Consulting</li>
          <li>Property Management</li>
          <li>Valuation and Appraisal Services</li>
        </ul>
        <p>
          Whether you're looking for your dream home, a prime business location, or lucrative real estate investment 
          opportunities, we have the experience and local market expertise to help you achieve your goals.
        </p>

        <h3>Our Commitment</h3>
        <p>
          At Enddy Nation, we believe in building lasting relationships with our clients. Our core values of integrity, 
          professionalism, and excellence are at the heart of everything we do. We pride ourselves on delivering solutions 
          that not only meet but exceed our clients’ expectations.
        </p>

        <h3>Our Location</h3>
        <p>
          We are centrally located at:<br />
          <strong>The Lion Yard, World Bank, Last Roundabout, No. 2 Umuguma Road, Owerri, Imo State.</strong>
        </p>

        <p>Feel free to visit our office or get in touch with us:</p>
        <p>
          <strong>Phone</strong>: 08069094410<br />
          <strong>Email</strong>: info@enddynationrealestate.com
        </p>

        <p>Let us help you take the next step in your real estate journey. At Enddy Nation Real Estate Company LTD, your satisfaction is our priority.</p>
      </section>
    </div>
  );
};

export default AboutUs;
