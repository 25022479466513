import React, { useState } from 'react';
import axios from 'axios'; // Make sure to import axios
import './ContactUs.css';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); // For success messages

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
  e.preventDefault();
  setLoading(true); // Set loading state
  setError(''); // Reset error state
  setSuccessMessage(''); // Reset success state

  // Add the heading to the message
  const modifiedFormData = {
    ...formData,
    message: `Message sent from contact us page. Main Message:\n\n${formData.message}`
  };

  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}contact/send-message`, modifiedFormData);
    if (res.status === 200) {
      setSuccessMessage('Message sent successfully!');
      setSubmitted(true); // Mark form as submitted
      setFormData({
        name: '',
        email: '',
        phone: '',
        message: '',
      });
    } else {
      const errorData = await res.json();
      setError(errorData.message || 'Failed to send message. Please try again later.');
    }
  } catch (error) {
    console.error('Error:', error);
    setError('An error occurred while sending your message. Please try again later.');
  } finally {
    setLoading(false); // Reset loading state
  }
};


  return (
    <div className="contact-us">
      <h1>Contact Us</h1>

      {/* Welcome Message */}
      <div className="welcome-message">
        <p>Welcome to Enddy Nation Real Estate! We're here to assist you with any inquiries you may have. Please fill out the form below or reach out to us directly.</p>
      </div>

      <div className="contact-info">
        <h3>Get In Touch</h3>
        <p><strong>Address:</strong> The Lion Yard, World Bank, Last Roundabout, No. 2 Umuguma Road, Owerri, Imo State</p>
        <p><strong>Phone:</strong> 08069094410</p>
        <p><strong>Email:</strong> info@enddynationrealestate.com</p>
      </div>

      {!submitted ? (
        <form className="contact-form" onSubmit={handleSubmit}>
          <h2>Send us a Message</h2>
          {error && <div className="error-message">{error}</div>} {/* Show error message */}
          {successMessage && <div className="success-message">{successMessage}</div>} {/* Show success message */}
          
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input 
              type="text" 
              id="name" 
              name="name" 
              value={formData.name} 
              onChange={handleChange} 
              required 
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input 
              type="email" 
              id="email" 
              name="email" 
              value={formData.email} 
              onChange={handleChange} 
              required 
            />
          </div>

          <div className="form-group">
            <label htmlFor="phone">Phone:</label>
            <input 
              type="tel" 
              id="phone" 
              name="phone" 
              value={formData.phone} 
              onChange={handleChange} 
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea 
              id="message" 
              name="message" 
              value={formData.message} 
              onChange={handleChange} 
              rows="5" 
              required 
            ></textarea>
          </div>

          <button type="submit" className="submit-button-c" disabled={loading}>
            {loading ? 'Sending...' : 'Send Message'} {/* Show loading text */}
          </button>
        </form>
      ) : (
        <div className="thank-you-message">
          <h2>Thank you for reaching out!</h2>
          <p>We have received your message and will get back to you shortly.</p>
        </div>
      )}

      {/* Google Map Widget */}
      <div className="map-container">
        <h2>Our Location</h2>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3975.9209312922984!2d7.01351601384282!3d5.473967995995926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1042cf1b672761e1%3A0xbbf5c22f680fdf63!2sUmuguma%20Rd%2C%20Owerri!5e0!3m2!1sen!2sng!4v1696430218762!5m2!1sen!2sng"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Enddy Nation Real Estate Company Location"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactUs;
