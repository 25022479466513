import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/Homepage';
import Header from './components/Header';
import Footer from './components/Footer';
import AdminPanel from './components/AdminPanel';
import ListingDetail from './pages/ListingDetail';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CookiePopup from './components/CookiePopup';
import AllListings from './pages/AllListings';



function App() {
  return (
    <Router>
       <CookiePopup />
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/control" element={<AdminPanel />} />
        <Route path="/details/:id" element={<ListingDetail />} />
        <Route path="/about" element={<AboutUs/>} />
        <Route path="/all_listings" element={<AllListings/>} />
        <Route path="/hello" element={<ContactUs/>} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
