import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import './Homepage.css';
import { Link } from 'react-router-dom';
import SortingBar from '../components/SortingBar.js'; // Import the SortingBar component

const Homepage = () => {
  const [listings, setListings] = useState([]); // State for listings
  const [loading, setLoading] = useState(true); // State for loading indicator
  const [filters, setFilters] = useState({
    sortBy: 'default', // Default filter for sorting
    propertyType: 'default', // Default filter for property type
    listingType: 'default', // Default filter for listing type
    bedrooms: 'default', // Default filter for bedrooms
  });
  const [error, setError] = useState(null); // State for errors

  // Fetch listings from API when filters change
  useEffect(() => {
    const fetchListings = async () => {
      setLoading(true);
      try {
        const query = new URLSearchParams(filters).toString();
        const response = await fetch(`${process.env.REACT_APP_API_URL}listings?${query}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        setListings(data); // Set the listings data from the response
      } catch (error) {
        console.error('Error fetching listings:', error);
        // Set an error state to display a message to the user
        setError('Failed to load listings. Please try again later.');
      } finally {
        setLoading(false); // Ensure loading state is reset
      }
    };

    fetchListings();
  }, [filters]);

   // Handle changes in sorting or filtering options
   const handleSortChange = (newFilters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters, // Update the filter state with the new values
    }));
  };

  // Slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 700,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <div>
      <main className="homepage">
        {/* Hero Slider Section */}
        <section className="hero-section">
          <Slider {...settings}>
            <div className="slide-1">
              <h2>Discover Luxury Homes</h2>
              <p className='slide-txt'>Explore high-end properties in prime locations.</p>
              <button className='slide-butn'>
                <Link to="/all_listings">View Listings</Link>
              </button>
            </div>
            <div className="slide-2">
              <h2>Discover Affordable Lands For Sale</h2>
              <p className='slide-txt'>Explore affordable properties in serene locations.</p>
              <button className='slide-butn'>
                <Link to="/all_listings">View Listings</Link>
              </button>
            </div>
            <div className="slide-3">
              <h2>Find Your Dream Home</h2>
              <p className='slide-txt'>We offer homes that suit all lifestyles and budgets.</p>
              <button className='slide-butn'>
                <Link to="/all_listings">View Listings</Link>
              </button>
            </div>
            <div className="slide-4">
              <h2>Commercial Properties</h2>
              <p className='slide-txt'>Looking for office spaces or retail locations? We have you covered.</p>
              <button className='slide-butn'>
                <Link to="/all_listings">View Listings</Link>
              </button>
            </div>
          </Slider>
        </section>

        {/* About Section */}
        <section className="about-section">
          <h3>About Us</h3>
          <p>
            At Enddy Nation Real Estate Company LTD, we specialize in offering
            premium real estate services, including buying, selling, and leasing properties.
          </p>
          <p>
            Our experienced team is here to help you find the best property that suits your needs.
          </p>
        </section>

        {/* Listings Preview Section */}
        <section id="listings-section" className="listings-preview">
          <h3>Featured Listings</h3>
          {/* Sorting Bar Section */}
          <section className="sorting-section">
            <SortingBar onSortChange={handleSortChange} />
          </section>

          {/* Show loading, error or listings */}
          {loading ? (
            <p>Loading listings...</p>
          ) : error ? ( // Display error message if error state exists
            <p className="error-message">Something went wrong. Please try again later.</p>
          ) : (
            <div className="listings-container">
              {listings.length > 0 ? (
                listings.map((listing) => (
                  <div className="listing-card" key={listing._id}>
                    <Link to={`/details/${listing._id}`}>
                      <div className="image-container1">
                        <img src={`${process.env.REACT_APP_API_URL}${listing.images[0]}`} alt={listing.title} loading="lazy" />
                        <p className="listing-type">{listing.listingType}</p>
                        {/* Conditionally show "New" label */}
                        {new Date() - new Date(listing.createdAt) <= 14 * 24 * 60 * 60 * 1000 && (
                          <p className="new-label">New</p>
                        )}
                      </div>
                      <h4>{listing.title}</h4>
                      <p className="listing-price">N{listing.price.toLocaleString()}NGN</p>
                      <p className="listing-details">
                        {listing.bedrooms || 0} Bed | {listing.bathrooms || 0} Bath | Parlor: {listing.livingRoomSize || 0}²m
                      </p>
                      <p className="listing-location">
                        Address: {listing.location.state}, {listing.location.town}, {listing.location.lga}, {listing.location.address}
                      </p>
                      <p className="listing-date">
                        Date Listed: {new Date(listing.createdAt).toLocaleDateString()}
                      </p>
                    </Link>
                  </div>
                ))
              ) : (
                <p>No listings available, change filter parameter.</p>
              )}
            </div>
          )}
        </section>
      </main>
    </div>
  );
};

export default Homepage;
