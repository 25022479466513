import React, { useState } from 'react';
import './SortingBar.css'; // Custom styles for sorting bar

const SortingBar = ({ onSortChange }) => {
  // Default filter values
  const initialFilters = {
    sortBy: 'default',
    propertyType: 'default',
    listingType: 'default',
    bedrooms: 'default',
  };

  // State to track each filter option
  const [sortOption, setSortOption] = useState(initialFilters.sortBy);
  const [propertyType, setPropertyType] = useState(initialFilters.propertyType);
  const [listingType, setListingType] = useState(initialFilters.listingType);
  const [bedrooms, setBedrooms] = useState(initialFilters.bedrooms);

  // Handle sorting changes
  const handleSortChange = (e) => {
    const newSortOption = e.target.value;
    setSortOption(newSortOption);
    onSortChange({ sortBy: newSortOption, propertyType, listingType, bedrooms });
  };

  // Handle property type changes
  const handlePropertyTypeChange = (e) => {
    const newPropertyType = e.target.value;
    setPropertyType(newPropertyType);
    onSortChange({ sortBy: sortOption, propertyType: newPropertyType, listingType, bedrooms });
  };

  // Handle listing type changes
  const handleListingTypeChange = (e) => {
    const newListingType = e.target.value;
    setListingType(newListingType);
    onSortChange({ sortBy: sortOption, propertyType, listingType: newListingType, bedrooms });
  };

  // Handle bedrooms changes
  const handleBedroomsChange = (e) => {
    const newBedrooms = e.target.value;
    setBedrooms(newBedrooms);
    onSortChange({ sortBy: sortOption, propertyType, listingType, bedrooms: newBedrooms });
  };

  // Reset filters to default values
  const handleReset = () => {
    // Reset local states to initial values
    setSortOption(initialFilters.sortBy);
    setPropertyType(initialFilters.propertyType);
    setListingType(initialFilters.listingType);
    setBedrooms(initialFilters.bedrooms);

    // Notify parent component to reset listings
    onSortChange(initialFilters);
  };

  return (
    <div className="sorting-bar">
      {/* Sort by Price */}
      <div className="sort-option">
        <label htmlFor="sortPrice">Sort by Price:</label>
        <select id="sortPrice" value={sortOption} onChange={handleSortChange}>
          <option value="default">Default</option>
          <option value="priceAsc">Price: Low to High</option>
          <option value="priceDesc">Price: High to Low</option>
        </select>
      </div>

      {/* Property Type */}
      <div className="sort-option">
        <label htmlFor="propertyType">Property Type:</label>
        <select id="propertyType" value={propertyType} onChange={handlePropertyTypeChange}>
          <option value="default">All Types</option>
          <option value="Land">Land</option>
          <option value="House">House</option>
          <option value="Apartment">Apartment</option>
          <option value="Commercial">Commercial</option>
        </select>
      </div>

      {/* Listing Type */}
      <div className="sort-option">
        <label htmlFor="listingType">Listing Type:</label>
        <select id="listingType" value={listingType} onChange={handleListingTypeChange}>
          <option value="default">All Listings</option>
          <option value="For Sale">For Sale</option>
          <option value="Rent">For Rent</option>
          <option value="Lease">For Lease</option>
          <option value="Short Let">Short Let</option>
        </select>
      </div>

      {/* Bedrooms */}
      <div className="sort-option">
        <label htmlFor="bedrooms">Bedrooms:</label>
        <select id="bedrooms" value={bedrooms} onChange={handleBedroomsChange}>
          <option value="default">Any</option>
          <option value="1">1 Bedroom</option>
          <option value="2">2 Bedrooms</option>
          <option value="3">3 Bedrooms</option>
          <option value="4">4+ Bedrooms</option>
        </select>
      </div>

      {/* Reset Button */}
      <div className="reset-option">
        <button onClick={handleReset}>Reset Filters</button>
      </div>
    </div>
  );
};

export default SortingBar;
